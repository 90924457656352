<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar :title="'임시 CMS'| translate">
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefresh"
        />
      </template>
    </my-tool-bar>
    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <template v-if="!ui.show.loading && !ui.show.subLoading">
        <cms-temp-base
          v-model="editContext"
          :account-id="accountId"
          @go-electrical="gotoElectrical"
        />
      </template>
      <template v-else>
        <progress-loading />
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 임대계약 및 프린터 계약 화면입니다.
 */

import {PATH} from "@/constants";
import {cloneDeep} from "lodash";
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import MyToolBar from "../../app/toolbar/MyToolBar";
import ToolbarSaveAction from "../../app/toolbar/ToolbarSaveAction";
import ProgressLoading from "../../part/ProgressLoading";
import VuexProps from "@/framework/vuex/VuexProps";
import CmsTempBase from "./CmsTempBase";

export default {
  name: "CmsTempRegister",
  components: {CmsTempBase, ProgressLoading, ToolbarSaveAction, MyToolBar},
  extends: WindowComponent,
  mixins: [VuexProps],
  props: {
    accountId: undefined,
    cmsMemberTempId: undefined,
  },
  data() {
    return {
      accountInfo: undefined,
      context: undefined,
      editContext: undefined,
      dummy: {
        cmsMemberTempId: undefined,
        rentalcompanyId: undefined,
        accountId: undefined,
        status: undefined,
        nickname: undefined,
        payerName: undefined,
        paymentKind: undefined,
        paymentNumber: undefined,
        bankCode: undefined,
        payerNumber: undefined,
        payerType: undefined,
        payerPhone: undefined,
        email: undefined,
        cardValidDate: undefined,
        cashReceiptIssue: undefined,
        cacheReceiptUmber: undefined,
        smsYn: undefined,
        serviceProvider: undefined,
        memo: undefined,
        cardHolidayYn: undefined,
        reason: undefined,
        gender: undefined,
        amount: undefined,
        billDay: undefined,
        agreement1Yn: undefined,
        agreement2Yn: undefined,
        requester: undefined,
        register: undefined,
      },
      ui: {
        show: {
          loading: false,
        }
      },
    }
  },
  watch: {
    context: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.editContext = cloneDeep(newValue)
      }
    },
  },
  mounted() {
    if(!this.cmsMemberTempId || this.cmsMemberTempId === 0 || this.cmsMemberTempId === '0') {
      this.loadAccountInfo()
    } else {
      this.loadCmsMemberTemp()
    }
    localStorage.setItem('ACCOUNT_DETAIL_TAB', 3)
  },
  unmounted() {
    this.context = undefined
    this.editContext = undefined
  },
  methods: {
    async loadCmsMemberTemp () {
      const response = await this.$request(PATH.ELECTRICAL.CMS_TEMP_SELECT).setObject({accountId: this.accountId, cmsMemberTempId: this.cmsMemberTempId}).enqueue()
      this.context = response.data[0]
    },

    async loadAccountInfo() {
      const response = await this.$request(PATH.ACCOUNT.GET)
        .setObject({
          accountId: this.accountId,
        })
        .enqueue();
      this.accountInfo = response
      const response2 = await this.$request(PATH.ACCOUNT.AUTOMATION)
        .setObject({
          accountId: this.accountId,
        })
        .enqueue();
      this.accountInfo.automation = response2

      const newItem = cloneDeep(this.dummy)

      newItem.accountId = this.accountId
      newItem.nickname = `${this.accountId}-cmstemp-${this.$now().toFormat('hhMMss')}`
      newItem.serviceProvider = 'F'
      newItem.payerName = this.accountInfo.ceoDetail.userFullname
      newItem.payerPhone = !this.accountInfo.company.companyTelno ? undefined : this.accountInfo.company.companyTelno.replace(/[^0-9]/g, '')
      newItem.email = this.accountInfo.chargeDetail.userEmail
      newItem.payerType = this.accountInfo.account.bizType === 'Y' ? 'B' : 'I'
      newItem.payerNumber = this.accountInfo.account.bizType === 'Y' ? this.accountInfo.company.businessNo : this.accountInfo.ceoDetail.userBirthday
      newItem.gender = 1
      newItem.paymentKind = 'B'
      newItem.billDay = this.accountInfo.account.billDay
      newItem.requester = this.accountInfo.ceoDetail.userFullname
      newItem.cardHolidayYn = 'Y'

      this.context = newItem
    },

    async onRefresh() {
      this.$dialog()
        .message(this.$translate('편집중인 정보를 초기화합니다.'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('초기화'), () => {
          this.editContext = cloneDeep(this.context)
        })
        .build().show();
    },

    async onSave() {
      const message = this.checkValidate()
      if (!!message) {
        return this.$snackbarError(this.$translate(message));
      }

      this.$dialog()
        .message(this.$translate('저장하시겠습니까?'))
        .buttonNegative(this.$translate('취소'))
        .buttonPositive(this.$translate('저장'), () => {
          this.cmsTempSave()
        })
        .build().show();
    },

    checkValidate() {
      let message
      this.editContext.payerNumber = !this.editContext.payerNumber ? undefined : this.editContext.payerNumber.replace(/[^0-9]/g, '')
      this.editContext.cardValidDate = !this.editContext.cardValidDate ? undefined : this.editContext.cardValidDate.replace(/[^0-9]/g, '')
      this.editContext.paymentNumber = !this.editContext.paymentNumber ? undefined : this.editContext.paymentNumber.replace(/[^0-9]/g, '')
      if (!this.editContext.serviceProvider ||
        !this.editContext.payerName ||
        !this.editContext.payerPhone ||
        !this.editContext.email ||
        !this.editContext.payerType ||
        !this.editContext.payerNumber ||
        !this.editContext.paymentKind ||
        (this.editContext.paymentKind === 'C' && !this.editContext.cardValidDate) ||
        (this.editContext.paymentKind === 'B' && !this.editContext.bankCode) ||
        !this.editContext.paymentNumber) {
        message = `${this.t('필수 항목을 작성하여 주세요')}`
      } else if (this.editContext.payerType === 'B' && this.editContext.payerNumber.length != 10) {
        message = `${this.t('사업자번호를 확인해 주세요.')} (${this.t('숫자만 입력해주세요')})`
      } else if (this.editContext.payerType === 'I' && this.editContext.payerNumber.length != 6) {
        message = `${this.t('생년월일을 확인해 주세요.')} (${this.t('주민번호 앞자리')}: YYMMDD)`
      } else if (this.editContext.paymentKind === 'C' && this.editContext.cardValidDate.length != 4) {
        message = `${this.t('카드유효기간을 확인해 주세요.')} (${this.t('월년')}: MMYY)`
      }

      return message
    },

    async cmsTempSave() {
      if (this.ui.show.loading) return
      this.ui.show.loading = true;

      try {
        const cmsTempRes = await this.$request(PATH.ELECTRICAL.CMS_TEMP_INSERT)
          .multipart()
          .appendObject('context', this.editContext)
          .enqueue();

        if (!!cmsTempRes && !!this.editContext.agreementFile && this.editContext.agreementFile.length > 0) {
          this.$snackbarSuccess(this.$translate("데이터를 저장했습니다."));
          const params = {
            cmsMemberTempId: cmsTempRes.cmsMemberTempId,
            accountId: cmsTempRes.accountId,
          }
          this.routerManager.replace({
            name: "CmsTempRegister",
            params
          });
        } else if (!!cmsTempRes && (!this.editContext.agreementFile || this.editContext.agreementFile.length <= 0)) {
          this.$snackbarSuccess(this.$translate("데이터를 저장했습니다."));
          // const params = {
          //   cmsMemberTempId: cmsTempRes.cmsMemberTempId,
          //   accountId: cmsTempRes.accountId,
          // }
          // this.routerManager.replace({
          //   name: "CmsTempRegister",
          //   params
          // });
          this.gotoElectricalCms(cmsTempRes)
        } else {
          throw new Error(cmsTempRes)
        }
      } catch (e) {
        console.error(e)
        return this.$snackbarError(this.$translate("데이터를 저장하지 못했습니다."));
      } finally {
        this.ui.show.loading = false;
      }
    },

    gotoElectricalCms(cmsTempRes) {
      this.$nextTick(() => {
        this.$dialog()
          .message(this.$translate('CMS 동의서 화면으로 이동하시겠습니까?'))
          .buttonNegative(this.$translate('취소'), () => {
            const params = {
              accountId: cmsTempRes.accountId,
              cmsMemberTempId: cmsTempRes.cmsMemberTempId,
            }
            this.routerManager.replace({
              name: "CmsTempRegister",
              params
            });
          })
          .buttonPositive(this.$translate('이동'), () => {
            const params = {
              accountId: this.accountId,
              cmsMemberTempId: cmsTempRes.cmsMemberTempId,
            }

            this.routerManager.replace({
              name: "ElectricalCms", params
            });
          })
          .build()
          .show();
      })
    },

    gotoElectrical() {
      this.$nextTick(() => {
        this.$dialog()
          .message(this.$translate('CMS 동의서 화면으로 이동하시겠습니까?'))
          .buttonNegative(this.$translate('취소'))
          .buttonPositive(this.$translate('이동'), () => {
            const params = {
              accountId: this.accountId,
              cmsMemberTempId: this.cmsMemberTempId,
            }

            this.routerManager.push({
              name: "ElectricalCms", params
            });
          })
          .build()
          .show();
      })
    },
  },
}
</script>

<style scoped>
</style>
