<template>
  <v-layout :align-center="true">
    <v-spacer />
    <v-btn
      icon
      @click.stop="onRefresh"
    >
      <!--<v-icon>mdi-refresh</v-icon>-->
      <img src="@/assets/images/main/svg/button_Refresh.svg">
    </v-btn>
    <v-btn
      icon
      @click.stop="onSave"
    >
      <!--<v-icon>mdi-content-save</v-icon>-->
      <img src="@/assets/images/main/svg/button_Save.svg">
    </v-btn>
  </v-layout>
</template>

<script>
    export default {
        name: "ToolbarSaveAction",
        props: {
            value: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                onGuard: false,
            }
        },
        computed: {
        },
        methods: {
            onCloseClicked() {
                this.$emit('close');
            },
            // 저장 버튼이 눌렸습니다.
            onSave() {
                if (this.onGuard) return;
                this.$emit('save');
            },
            // 리프레쉬 버튼이 눌러졌습니다.
            onRefresh() {
                if (this.onGuard) return;
                this.$emit('refresh');
            },
        },
    }
</script>

<style scoped>

</style>
